@use 'styles';

:root {
  --site-content-width: 80%;
  --site-content-margin: 20%;
  --site-content-half-margin: 10%;
  --site-content2-width: 94%;
  --site-content2-margin: 6%;
  --site-content2-half-margin: 3%;
  --site-min-width: 280px;
  --site-max-width: 1920px;
  --transition-time: 1.2s;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 10px;
}
img {
  image-rendering: auto;
}

body {
  min-width: var(--site-min-width);
  background-color: styles.$silver;
  color: styles.$black;
  @include styles.scrollbarY;
}
body,
input,
textarea,
button,
select {
  scroll-behavior: smooth;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: inherit;
  text-decoration: none;
}
iframe {
  display: block;
  border: none;
}
button,
input,
textarea,
select {
  color: styles.$black;
  outline: none;
  border: none;
  background-color: transparent;
}
select {
  appearance: none;
}
textarea {
  resize: none;
}
img {
  display: block;
}
ul,
ol {
  list-style: none;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 16px;
  outline: 1px solid styles.$d-blue;
  border-radius: 10px;
}

.p,
span,
a,
div,
textarea,
ul,
ol,
li {
  white-space: pre-line;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
