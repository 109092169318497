@use 'colors';

.shadow-1 {
  filter: drop-shadow(0 4px 11px rgba(#202020, 36%));
}
.shadow-2 {
  filter: drop-shadow(0 5px 17px rgba(#000000, 10%));
}
.shadow-3 {
  filter: drop-shadow(0 6px 20px rgba(colors.$grey-blue, 30%));
}
.shadow-4 {
  filter: drop-shadow(0 9px 25px rgba(colors.$grey-blue, 40%));
}
