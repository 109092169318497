@use '../../styles';

.contentWrapper {
  display: flex;
  align-items: center;
}
.iconWrapper {
  flex-shrink: 0;
}
.buttonAnimation {
  display: flex;
}
.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  justify-content: center;
  border-radius: 4px;
  user-select: none;
  transition: all var(--transition-time), outline 0s;
  p {
    line-height: unset !important;
  }
  &:disabled {
    background-color: styles.$l-blue !important;
    color: styles.$grey-blue !important;
    cursor: not-allowed !important;
    outline: none !important;
  }
}
.extraBigSize {
  padding: 3.5rem 7rem;
  border-radius: 10px;
}
.bigger {
  padding: 3.2rem 5.2rem;
  gap: 6rem;
  border-radius: 10px;
}
.bigSize {
  padding: 2.4rem 2rem;
  gap: 6rem;
  border-radius: 10px;
}
.normalSize {
  padding: 1.6rem 3.2rem;
}
.mediumSize {
  padding: 1.4rem 6rem;
}
.smallSize {
  padding: 0.8rem 2.4rem;
  gap: 2.5em;
}
.primary {
  background-color: styles.$m-blue;
  color: styles.$white;
  &:hover {
    background-color: styles.$navy;
  }
  &:focus {
    outline: 2px solid styles.$black;
  }
  &:active {
    background-color: styles.$d-blue;
  }
}
.secondary {
  background-color: styles.$white;
  outline: 1px solid styles.$black;
  color: styles.$m-blue;
  &:hover {
    background-color: styles.$l-blue;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
}
.tertiary {
  background-color: styles.$white;
  color: styles.$m-blue;
  &:hover {
    background-color: styles.$l-blue;
  }
  &:focus {
    outline: 2px solid styles.$d-grey;
  }
  &:active {
    outline: none;
    background-color: styles.$sky;
  }
}
.orange {
  background-color: styles.$m-yellow;
  color: styles.$white;
  &:hover {
    background-color: styles.$lemon;
    color: styles.$white;
  }
}
.blackOutline {
  background-color: transparent;
  color: styles.$black;
  border: 1px solid styles.$black;
  &:hover {
    color: styles.$white;
    background-color: styles.$black;
  }
  &.selected {
    color: styles.$white;
    border: none;
    background-color: styles.$azure;
  }
}
.archive {
  background-color: transparent;
  color: styles.$black;
  border: 1px solid styles.$lemon;
  &:hover {
    color: styles.$white;
    background-color: styles.$black;
  }
  &.selected {
    color: styles.$white;
    border: none;
    background-color: styles.$d-yellow;
  }
}
.blackOutlineSectors {
  @extend .blackOutline;
  &:hover {
    color: styles.$white;
    background-color: styles.$azure;
    outline: none;
    gap: 2rem;
  }
}
.gray {
  background-color: styles.$l-grey;
  color: styles.$white;
  &:disabled {
    background-color: styles.$l-grey !important;
    color: styles.$white !important;
  }
}
.green {
  background-color: styles.$herbal;
  color: styles.$white;
}
.sale {
  background-color: styles.$white;
  color: styles.$herbal;
}
.azure {
  @extend .primary;
  background-color: styles.$azure;
}
.navy {
  @extend .primary;
  background-color: styles.$navy;
}
.error {
  background-color: styles.$raspberry;
  color: styles.$white;
}
