@use '../../styles';

.sloganBar {
  width: 100%;
  background-color: styles.$black;
  color: styles.$white;
  text-align: center;
  padding: 1em 0;
  @media (max-width: 600px) {
    padding: 1em var(--site-content2-margin);
  }
}
