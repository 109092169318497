@use '../../styles';

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  .contentWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: styles.$black;
      opacity: 70%;
    }
    .content {
      @include styles.scrollbarY;
      @extend .shadow-2;
      position: relative;
      max-height: 100%;
      background-color: styles.$l-blue;
      border-radius: 20px;
      @media (max-width: 600px) {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 0;
      }
      .close {
        cursor: pointer;
        position: absolute;
        top: 2em;
        right: 2em;
      }
    }
  }
}
