@use '../../styles';

.socialMedia {
  display: flex;
  align-items: center;
  gap: 1em;
  div {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  i {
    font-size: 30px;
  }
}

.footer {
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 10rem;
  background-color: #4196f0;
  .content {
    position: relative;
    margin: 0 auto;
    max-width: var(--site-max-width);
    padding-left: 192px;
    padding-right: 192px;
    padding-top: 10em;
    padding-bottom: 10em;
    color: styles.$white;
    @media (max-width: 1200px) {
      padding-left: 2%;
      padding-right: 2%;
    }
    @media (max-width: 1800px) {
      padding-top: 2em;
    }
    .grid {
      grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
      display: grid;
      column-gap: 2em;
      @media (max-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
      .gridItem {
        display: flex;
        flex-direction: column;
        gap: 8em;
        @media (max-width: 600px) {
          gap: 3em;
        }
        @media (max-width: 1200px) {
          margin-bottom: 8em;
        }
        @media (max-width: 1000px) {
          align-items: center;
          text-align: center;
        }
        &.spanFull {
          @media (max-width: 1600px) {
            margin-bottom: 5em;
            grid-column-end: span 6;
          }
          @media (max-width: 768px) {
            grid-column-end: span 1;
          }
        }
        ul {
          p {
            cursor: pointer;
          }
          li {
            margin-bottom: 1em;
          }
        }
        .textBlock {
          margin-right: 2em;
          @media (max-width: 1200px) {
            margin-right: 0;
          }
        }
      }
    }
    .searchBarWrapper {
      width: 100%;
      display: flex;
      justify-content: right;
      margin-top: 4em;
      @media (max-width: 1000px) {
        justify-content: center;
      }
      .searchBar {
        width: 35em;
      }
    }
    .bottom {
      width: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      img {
        width: 35%;
      }
      @media (max-width: 1000px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 5em;
        margin: 5em 0;
      }
      .back {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        gap: 1em;
        transform: translateX(50%);
        .backIcon:hover {
          transform: scale(120%);
          transition: transform var(--transition-time);
        }
        @media (max-width: 1000px) {
          transform: none;
        }
      }
    }
    .copyright {
      display: flex;
      justify-content: flex-end;
      @media (max-width: 1000px) {
        justify-content: center;
      }
    }
  }
}
