@use '../../styles';

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: styles.$white;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.backgroundImage {
  img {
    width: 100%;
  }
}
.headerButtons {
  margin: 0 2em;
  display: flex;
  align-items: center;
  gap: 2em;
  b {
    font-size: 0.7em;
    white-space: nowrap;
  }
  @media (max-width: 350px) {
    margin: 2em 2em;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
}
.headerList {
  width: 100%;
  overflow-y: auto;
}
.searchBarWrapper {
  display: flex;
  justify-content: center;
  padding: 0.5em 0;
  border-bottom: 1px solid styles.$black;
  .searchBar {
    width: 95%;
  }
}
.absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mainList {
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  margin-top: 1.5em;
  .mainListItem {
    position: relative;
    cursor: pointer;
    user-select: none;
    .mainListItemContent {
      display: flex;
      gap: 1em;
      justify-content: space-between;
      padding: 2em 2.5em;
      align-items: center;
      color: styles.$white;
    }
  }
}
.listDropdown {
  display: flex;
  flex-direction: column;
  .listDropdownItem {
    cursor: pointer;
    user-select: none;
    display: flex;
    padding: 0.5em 2.5em;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid styles.$black;
  }
}
.secondaryList {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 4em;
  .secondaryListItem {
    cursor: pointer;
    user-select: none;
    display: flex;
    gap: 1em;
    padding: 2em 2.5em;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid styles.$black;
    border-bottom: 1px solid styles.$black;
  }
}
.loginButtons {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  margin: 6em 0;
  b {
    white-space: nowrap;
  }
}
.button {
  cursor: pointer;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
