// Neutral
$black: #212121;
$d-grey: #4d4d4d;
$grey: #717171;
$l-grey: #89939e;
$grey-blue: #abbed1;
$silver: #f5f7fa;
$white: #fefefe;
// Primary
$d-blue: #0053ad;
$navy: #0663c7;
$m-blue: #0671e0;
$azure: #4196f0;
$sky: #dbedff;
$l-blue: #eef5fc;
// Success
$d-green: #115b43;
$forest: #1b6e53;
$m-green: #009262;
$herbal: #32c997;
$mint: #84dfc1;
$l-green: #f1fbf8;
// Warning
$d-yellow: #e48900;
$lemon: #ffa826;
$m-yellow: #ffb240;
$straw: #ffc670;
$sand: #ffd596;
$l-yellow: #fff8ec;
// Danger
$d-red: #c33025;
$scarlet: #e01507;
$m-red: #e02b1d;
$raspberry: #ff5a4f;
$rubicund: #f0857d;
$l-red: #fff1f0;
