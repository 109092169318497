@use '../../styles';

.container {
  @extend .shadow-4;
  position: fixed;
  width: 100%;
  padding: 2.5rem;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: styles.$white;
  gap: 4rem;

  @media (max-width: 500px) {
    flex-direction: column;
  }
}

.products {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.product {
  @extend .shadow-3;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: styles.$l-blue;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: inherit;
  }
}

.close {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: styles.$azure;
  cursor: pointer;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
}
