@use '../../styles';

.icons {
  display: flex;
  align-items: center;
  gap: 1em;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  .iconName {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;
    white-space: nowrap;
    > p {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.iconWrapper {
  width: 100%;
  height: 100%;
  padding: 0.2em 0.25em;
  background-color: transparent;
  &.activeIconWrapper {
    background-color: styles.$navy;
    border-radius: 5px;
    svg {
      stroke: styles.$white;
      fill: styles.$white;
    }
  }
  .iconDisabled {
    stroke: styles.$l-grey;
    fill: styles.$l-grey;
    cursor: default;
  }
  .icon {
    stroke: styles.$navy;
    fill: styles.$navy;
    cursor: pointer;
  }
}
