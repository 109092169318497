@use '../../styles';

.wrapper {
  display: flex;
  position: relative;
  z-index: 1;
  .icon {
    position: absolute;
    width: var(--search-icon-width);
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
  .searchBar {
    width: 100%;
    padding: 1.6rem 0 1.6rem var(--search-icon-width);
    color: styles.$black;
    &.selected {
      text-decoration: underline;
    }
    &::placeholder {
      font-weight: 400;
      color: styles.$l-grey;
    }
  }
  .dropdown {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    left: 0;
    top: calc(100% + 1em);
    border-radius: 10px;
    padding: 1.2em 1.5em;
    background-color: styles.$white;
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    .dropdownItem {
      color: styles.$black;
      cursor: pointer;
      user-select: none;
      .hover {
        font-weight: 700;
        text-decoration: underline;
      }
      .hoverDisabled {
        color: styles.$grey;
      }
    }
  }
}
.defaultStyle {
  --search-icon-width: 6.4rem;
  background-color: styles.$l-blue;
  border-radius: 10px;
  .searchBar {
    background-color: styles.$l-blue;
    border-radius: 10px;
  }
}
.shopStyle {
  --search-icon-width: 4.5rem;
  background-color: transparent;
  border-bottom: 1px solid styles.$black;
  .searchBar {
    background-color: transparent;
  }
}
