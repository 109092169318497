.h1Style {
  @extend h1;
}

.h4Style{
  @extend h4;
}

.pStyle{
  @extend p;
}

h1 {
  font-weight: 600;
  font-size: 6.4rem;
  line-height: 7.6rem;
}
h2 {
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 4.4rem;
}
h3 {
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.6rem;
}
h4 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
}
.body-1 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
}
.body-2 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.body-3 {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
}
.body-4 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
button,
a,
p,
span,
select {
  &.normal {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  &.medium-bold {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  &.medium {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  &.small {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}
input,
textarea {
  &.text-1 {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
.add-bold {
  font-weight: 700 !important;
}
@media (max-width: 768px) {
  h1 {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }
  h2 {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  h3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  h4 {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .body-1 {
    font-size: 1.5rem;
    line-height: 2.4rem;
  }
  .body-2 {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .body-3 {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .body-4 {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  button,
  a,
  p,
  span,
  select {
    &.normal {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
    &.medium-bold {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
    }
    &.medium {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
    }
    &.small {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
  input,
  textarea {
    &.text-1 {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
}
