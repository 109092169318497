@use '../../styles';

.header {
  overflow-x: hidden;
  width: 100%;
  transition: top 0.3s;
  background-color: styles.$silver;
  z-index: 2;
}
p.noLink {
  cursor: inherit;
}
.upper {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: var(--site-content2-half-margin);
  @media (max-width: 1600px) {
    flex-direction: column;
    padding-right: 0;
    gap: 2em;
    margin-bottom: 2em;
    margin-top: 2em;
  }
  @media (max-width: 768px) {
    gap: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
  .logo {
    position: relative;
    width: min-content;
    @media (max-width: 1600px) {
      width: 100%;
      background-color: styles.$azure;
    }
    @media (max-width: 768px) {
      display: block;
      width: min-content;
      background-color: unset;
    }
    .logoImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 4em;
      @media (max-width: 1600px) {
        position: static;
      }
      @media (max-width: 768px) {
        position: absolute;
        padding-right: 2em;
      }
      img {
        @media (max-width: 768px) {
          width: 8em;
        }
      }
    }
  }
  .upperWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1600px) {
      width: var(--site-content2-width);
    }
    @media (max-width: 1300px) {
      flex-direction: column;
      gap: 2em;
    }
    .left {
      display: flex;
      align-items: center;
      column-gap: 3em;
      row-gap: 1em;
      margin: 0 3em;
      flex-wrap: wrap;
      > span {
        white-space: nowrap;
      }
      > p {
        cursor: pointer;
      }
      .selected {
        color: styles.$azure;
        @extend .shadow-3;
      }
      @media (max-width: 1600px) {
        margin: 0;
      }
      @media (max-width: 700px) {
        flex-direction: column;
        gap: 2em;
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 6em;
      > p {
        cursor: pointer;
      }
      @media (max-width: 1700px) {
        gap: 2em;
      }
      @media (max-width: 700px) {
        flex-direction: column;
      }
      .rightButtons {
        display: flex;
        justify-content: space-between;
        gap: 9em;
        height: 56px;
        @media (max-width: 1700px) {
          gap: 3em;
        }
        @media (max-width: 700px) {
          flex-direction: column;
          gap: 2em;
        }
        .consultingButton {
          white-space: nowrap;
        }
      }
    }
  }
}
.lower {
  background-color: styles.$navy;
  color: styles.$white;
  padding: 3em var(--site-content2-half-margin);
  .lowerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    gap: 2em;
    .left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 2.5em;
      margin-right: 5rem;
      a {
        font-size: 1.5rem;
      }
      > * {
        border-bottom: 2px solid transparent;
      }
      .selected {
        border-bottom: 2px solid styles.$white;
      }
      @media (max-width: 1800px) {
        margin: 0;
      }
    }
    .right {
      display: flex;
      justify-content: right;
      @media (max-width: 1500px) {
        justify-content: center;
      }
      .searchBar {
        width: 30em;
        @media (max-width: 320px) {
          width: 20em;
        }
      }
    }
  }
}
